import React, { useEffect , useState } from "react";
import { Form, Input, Button, Select, DatePicker, message } from "antd";
import { useTranslation } from "react-i18next";
import { style } from "../../Styles";
import { countryPhoneCode } from "../../Utils/country-phone-codes";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { use } from "i18next";

const SpecialCardForm = ({
  onSave,
  onSaveFailed,
  specialCard,
  onCancel,
  onDirty,
  unsavedChanges,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState(null);
  const [isValidRange, setIsValidRange] = useState(false);
  const { RangePicker } = DatePicker;
  const { cardTypes } = useSelector((state) => state.cardTypes);
  

  const initialValues = {
    cardNumber: specialCard?.cardNumber,
    name: specialCard?.cardHolder?.name,
    surname: specialCard?.cardHolder?.surname,
    idNumber: specialCard?.cardHolder?.identityCardNumber,
    email: specialCard?.cardHolder?.email,
    phoneCountryCode: specialCard?.cardHolder?.phoneCountryCode,
    phone: specialCard?.cardHolder?.phone,
    cardType:
      cardTypes?.length > 0
        ? cardTypes.find(
            (type) => type.id === specialCard?.populatedCardType?.id
          )?.id
        : undefined,
    cardValidRange: [
      dayjs(specialCard?.cardValidFrom),
      dayjs(specialCard?.cardValidTo),
    ],
  };

  const onSetValidityDate = (dates) => {
    setDateRange(dates);
  };

  const handleDateChange = (dates) => {
    if (dates) {
      const [startDate, endDate] = dates;

      const oneYearLater = startDate.clone().add(1, "year");

      if (endDate.isAfter(oneYearLater)) {
        message.warning(t("specialCardScreen.form.validityDateWarning")); 
        onSetValidityDate([startDate, oneYearLater]);
        setIsValidRange(false);
      } else {
        onSetValidityDate(dates);
        setIsValidRange(true);
      }
    } else {
      onSetValidityDate(null);
    }
  };
  useEffect(() => {
    if (specialCard) {
      form.setFieldsValue({
        cardNumber: specialCard?.cardNumber,
        name: specialCard?.cardHolder?.name,
        surname: specialCard?.cardHolder?.surname,
        idNumber: specialCard?.cardHolder?.identityCardNumber,
        email: specialCard?.cardHolder?.email,
        phoneCountryCode: specialCard?.cardHolder?.phoneCountryCode,
        phone: specialCard?.cardHolder?.phone,
        cardType:
          cardTypes?.length > 0
            ? cardTypes.find(
                (type) => type.id === specialCard?.populatedCardType?.id
              )?.id
            : undefined,
        cardValidRange: [
          dayjs(specialCard?.cardValidFrom),
          dayjs(specialCard?.cardValidTo),
        ],
        dateOfBirth: dayjs(specialCard?.cardHolder?.dateOfBirth),
      });
      setIsValidRange(true);
    }
  }, [specialCard, form]);

  const handleSubmit = (values) => {
    onSave(values);
  };

  return (
    <Form
      form={form}
      name="specialCard"
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
      onFinishFailed={onSaveFailed}
      autoComplete="off"
      onValuesChange={() => onDirty()}
    >
      <Form.Item
        label={t("specialCardScreen.drawer.cardId")}
        name="cardNumber"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseEnterCardId"),
          },
        ]}
      >
        <Input disabled={specialCard} />
      </Form.Item>
      <Form.Item
        label={t("specialCardScreen.drawer.name")}
        name="name"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseEnterName"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("specialCardScreen.drawer.surname")}
        name="surname"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseEnterSurname"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("specialCardScreen.drawer.idNumber")}
        name="idNumber"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseEnterIdNumber"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("specialCardScreen.drawer.dateOfBirth")}
        name="dateOfBirth"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseEnterDateOfBirth"),
          },
        ]}
      >
        <DatePicker style={{ ...style.headerButton, width: "100%" }} />
      </Form.Item>
      <Form.Item
        label={t("specialCardScreen.drawer.email")}
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: t("specialCardScreen.form.pleaseEnterValidEmail"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("specialCardScreen.drawer.phoneCountryCode")}
        name="phoneCountryCode"
        defaultValue={""}
        rules={[
          {
            required: true,
            message: t("userScreen.form.pleaseEnterPhoneCountryCode"),
          },
        ]}
      >
        <Select
          size="medium"
          showSearch
          optionFilterProp="filterBy"
          allowClear
          optionLabelProp="label"
        >
          {countryPhoneCode.map((p) => (
            <Select.Option
              key={p.en}
              filterBy={`${p.en} +${p.phoneCode}`}
              value={p.phoneCode}
              label={
                <>
                  <span role="img">{p.emoji}</span>
                  {` +(${p.phoneCode})`}
                </>
              }
            >
              <span role="img">{p.emoji}</span>{" "}
              <span style={{ color: "gray" }}>{`+(${p.phoneCode})`}</span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("specialCardScreen.drawer.phone")}
        name="phone"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseEnterPhone"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("specialCardScreen.drawer.cardType")}
        name="cardType"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseSelectCardType"),
          },
        ]}
      >
        <Select disabled={specialCard} >
          {cardTypes?.length > 0 ? (
            cardTypes.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.name}
              </Select.Option>
            ))
          ) : (
            <Select.Option disabled>
              {t("specialCardScreen.form.noCardTypesAvailable")}
            </Select.Option>
          )}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("specialCardScreen.drawer.cardValidity")}
        name="cardValidRange"
        rules={[
          {
            required: true,
            message: t("specialCardScreen.form.pleaseSelectCardValidityRange"),
          },
        ]}
      >
        <RangePicker
          style={{ ...style.headerButton, width: "100%" }}
          defaultValue={dateRange}
          format={'DD-MM-YYYY'}
          onChange={handleDateChange}
          allowClear={false}
          placeholder={[t("drawerFilter.startDate"), t("drawerFilter.endDate")]}
        />
      </Form.Item>

      <Form.Item style={{ float: "right" }}>
        <Button
          type="default"
          style={{ marginBottom: 32, marginRight: 8 }}
          onClick={() => onCancel(unsavedChanges)}
        >
          {t("specialCardScreen.form.cancel")}
        </Button>
        <Button
          type="primary"
          style={{ marginBottom: 32 }}
          htmlType="submit"
          disabled={!unsavedChanges || !isValidRange}
        >
          {!specialCard
            ? t("specialCardScreen.form.save")
            : t("specialCardScreen.form.update")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SpecialCardForm;
