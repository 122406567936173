import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";

const initialState = {
  cards: {loading: false , data : [], totalCards: 0},
  selectedCard: {loading: false , data : {}},
};

export const getCards = createAsyncThunk("Card/getCards", async (data) => {
  try {
    const response = await axios.get(`/card?page=${data?.page - 1}&size=${data?.pageSize}&search=${data?.search}`);
    return response.data;
  } catch (error) {
    const customError = {
      name: error.code,
      message: error.message,
    };
    throw customError;
  }
});

export const getSpecialCards = createAsyncThunk("Card/getSpecialCards", async (data) => {
  try {
    const response = await axios.get(`/special-card?page=${data?.page - 1}&size=${data?.pageSize}&search=${data?.search}`);
    return response.data;
  } catch (error) {
    const customError = {
      name: error.code,
      message: error.message,
    };
    throw customError;
  }
});

export const getCardsBetweenDate = createAsyncThunk(
  "card/getCardsBetweenDate",
  async (data) => {
    try {
      const response = await axios.patch(`/card/filter`, data);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.code,
        message: error.message,
      };
      throw customError;
    }
  }
);

export const saveSpecialCard = createAsyncThunk(
  "card/saveCard",
  async (data) => {
    try {
      const response = await axios.post(`/card/special`, data);
      return response.data;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response.data.message,
      };
      throw customError;
    }
  }
);

export const changeCardStatus = createAsyncThunk(
  "card/changeCardStatus",
  async (data) => {
    try {
      const response = await axios.put(`/card/${data?.id}/active`, null, {
        params: { active: data?.status },
      });
      return response.data;
    } catch (error) {
      const customError = {
        name: error.name,
        message: error.response?.data?.message || error.message,
      };
      throw customError;
    }
  }
);

export const deleteCard = createAsyncThunk("/card/deleteCard", async (id) => {
  try {
    const response = await axios.delete(`/card/${id}`);
    return response.data;
  } catch (error) {
    const customError = {
      name: error.name,
      message: error.response.data.message,
    };
    throw customError;
  }
});

export const updateCard = createAsyncThunk("card/updateCard", async (data) => {
  try {
    const response = await axios.put(`/card/${data.id}`, data);
    return response.data;
  } catch (error) {
    const customError = {
      name: error.name,
      message: error.response.data.message,
    };
    throw customError;
  }
});

export const getCardById = createAsyncThunk("card/getCardById", async (id) => {
  try {
    const response = await axios.get(`/card/${id}`);
    return response.data;
  } catch (error) {
    const customError = {
      name: error.name,
      message: error.response.data.message,
    };
    throw customError;
  }
});

export const cardSlice = createSlice({
  name: "Card",
  initialState,
  reducers: {},
  extraReducers: {
    [getCards.fulfilled]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = action.payload?.content || [];
      state.cards.totalCards = action.payload?.totalElements || 0; 
    },
    [getCards.rejected]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = [];
    },
    [getCards.pending]: (state) => {
      state.cards.loading = true;
    },
    [getSpecialCards.fulfilled]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = action.payload?.content || [];
      state.cards.totalCards = action.payload?.totalElements || 0; 
    },
    [getSpecialCards.rejected]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = [];
    },
    [getSpecialCards.pending]: (state) => {
      state.cards.loading = true;
    },
    [getCardsBetweenDate.fulfilled]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = action.payload.filter(card => {
        return card.cardHolder == null && card.populatedCardType == null;
      });
    },
    [getCardsBetweenDate.rejected]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = [];
    },
    [getCardsBetweenDate.pending]: (state) => {
      state.cards.loading = true;
    },
    [changeCardStatus.fulfilled]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = state.cards.data.map((card) => {
        if (card.id === action.payload.id) {
          return action.payload;
        }
        return card;
      });
    },
    [changeCardStatus.rejected]: (state, action) => {
      state.cards.loading = false;
    },
    [changeCardStatus.pending]: (state) => {
      state.cards.loading = true;
    },
    [deleteCard.fulfilled]: (state, action) => {
      state.cards.loading = false;
      state.cards.data = state.cards.data.filter((card) => card.id !== action.payload);
    },
    [deleteCard.rejected]: (state, action) => {
      state.cards.loading = false;
    },
    [deleteCard.pending]: (state) => {
      state.cards.loading = true;
    },
    [updateCard.fulfilled]: (state, action) => {
      state.cards.data = state.cards.data.map((card) => 
        card.id === action.payload.id ? action.payload : card
      );
    },
    [updateCard.rejected]: (state, action) => {
      state.cards.loading = false;
    },
    [updateCard.pending]: (state) => {
      state.cards.loading = true;
    },
    [getCardById.fulfilled]: (state, action) => {
      state.selectedCard.loading = false;
      state.selectedCard.data = action.payload;
    },
    [getCardById.rejected]: (state, action) => {
      state.selectedCard.loading = false;
    },
    [getCardById.pending]: (state) => {
      state.selectedCard.loading = true;
    },
    [saveSpecialCard.fulfilled]: (state, action) => {
      state.cards.loading = false;
      state.cards.data.push(action.payload);
    },
    [saveSpecialCard.rejected]: (state, action) => {
      state.cards.loading = false;
    },
    [saveSpecialCard.pending]: (state) => {
      state.cards.loading = true;
    },
  },
});

export default cardSlice.reducer;
